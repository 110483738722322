var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card px-2 py-4"},[_c('div',{staticClass:"container-fliud"},[_c('div',{staticClass:"wrapper row"},[_c('div',{staticClass:"preview col-md-6"},[_c('div',{staticClass:"preview-pic tab-content"},[(_vm.activatedImage)?_c('div',{staticClass:"tab-pane active"},[_c('img',{staticClass:"rounded",attrs:{"src":_vm.activatedImage}})]):_vm._e()]),(_vm.images.length > 1)?_c('ul',{staticClass:"preview-thumbnail nav nav-tabs"},_vm._l((_vm.images),function(image,key){return _c('li',{key:("command-price-" + key)},[_c('a',{class:[
									'thumbnail rounded relative block',
									_vm.activatedImage == image
										? ''
										: 'disabled'
								],on:{"click":function($event){return _vm.selectImage(image)}}},[_c('div',{staticClass:"rounded bg-cover w-16 h-16",style:(("background-image: url(" + image + ")"))})])])}),0):_vm._e(),_c('hr')]),_c('div',{staticClass:"details col-md-6"},[_c('h6',{staticClass:"-my-1"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.command.created_at, true))+" ")]),_c('br'),(_vm.command.table && _vm.command.table.restaurant)?_c('div',[_c('span',{staticClass:"review-no"},[_c('h6',[_vm._v("Restaurant:")]),_c('div',{staticClass:"bg-grey-100 p-2 my-2 rounded"},[_vm._v(" "+_vm._s(_vm.command.table.restaurant.name)+" ")])])]):_vm._e(),(_vm.command.client)?_c('div',[_c('span',{staticClass:"review-no"},[_c('h6',[_vm._v("Client:")]),_c('div',{staticClass:"bg-grey-100 p-2 my-2 rounded"},[_vm._v(" "+_vm._s(_vm.command.client.user.name)+" ")])])]):_vm._e(),(_vm.command.table && _vm.command.table)?_c('div',[_c('span',{staticClass:"review-no"},[_c('h6',[_vm._v("Table:")]),_c('div',{staticClass:"bg-grey-100 p-2 my-2 rounded"},[(!_vm.isEditing)?_c('div',[_vm._v(" #"+_vm._s(_vm.command.table.number)+" ")]):_c('div',[_c('t-select',{attrs:{"options":[
											{
												value: '',
												label: ''
											} ].concat( _vm.tables.map(function (el) { return ({
												value: el.id,
												label: ("#" + (el.number))
											}); })
										)},model:{value:(_vm.form.tableId),callback:function ($$v) {_vm.$set(_vm.form, "tableId", $$v)},expression:"form.tableId"}})],1)])])]):_vm._e(),(_vm.command.planifiedFor)?_c('div',[_c('span',{staticClass:"review-no"},[_c('h6',[_vm._v("Planifier pour:")]),_c('div',{staticClass:"bg-grey-100 p-2 my-2 rounded"},[(!_vm.isEditing)?_c('div',[_vm._v(" "+_vm._s(_vm.command.planifiedFor)+" ")]):_c('div',[_c('t-datepicker',{model:{value:(_vm.form.planifiedFor),callback:function ($$v) {_vm.$set(_vm.form, "planifiedFor", $$v)},expression:"form.planifiedFor"}})],1)])])]):_vm._e(),(_vm.command.comment)?_c('div',[_c('span',{staticClass:"review-no"},[_c('h6',[_vm._v("Commentaire:")]),_c('div',{staticClass:"bg-grey-100 p-2 my-2 rounded"},[(!_vm.isEditing)?_c('div',[_vm._v(" "+_vm._s(_vm.command.comment)+" ")]):_c('div',[_c('t-textarea',{attrs:{"type":"text"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1)])])]):_vm._e(),(_vm.command.address.description != 'N/A')?_c('div',{staticClass:"vote"},[_c('h6',[_vm._v("Adresse:")]),_c('div',{staticClass:"bg-grey-100 p-2 my-2 rounded"},[(!_vm.isEditing)?_c('div',[_vm._v(" "+_vm._s(_vm.command.address.description)+" ")]):_c('div',[_c('t-input',{attrs:{"type":"text"},model:{value:(_vm.command.address.description),callback:function ($$v) {_vm.$set(_vm.command.address, "description", $$v)},expression:"command.address.description"}})],1)])]):_vm._e(),_c('h6',{staticClass:"mb-2"},[_vm._v("Les lignes de commande:")]),_vm._l((_vm.clients),function(clientId,key){return _c('div',{key:("client-" + key),staticClass:"bg-grey-100 p-2 my-2 rounded"},[(
								_vm.getUserByClientId(clientId).id ==
									_vm.command.clientId
							)?_c('div',{staticClass:"p-2 -m-2 mb-2 bg-grey-300"},[_c('span',{staticClass:"uppercase"},[_vm._v(_vm._s(_vm.getUserByClientId(clientId).name))]),_vm._v(" - "+_vm._s(_vm.getClientById(clientId).phone)+" ")]):_vm._e(),_vm._l((_vm.form.lines.filter(
								function (el) { return el.clientId == clientId; }
							)),function(line,key){return _c('div',{key:("line-" + key),staticClass:"my-2"},[(_vm.isEditing)?_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"m-2"},[_c('button',{staticClass:"m-auto bg-red-600 text-white px-2 py-1 text-xs w-full rounded",on:{"click":function($event){return _vm.removePrice(key)}}},[_c('i',{staticClass:"icofont-ui-remove"})])]),_c('div',{staticClass:"w-16 m-2"},[_c('t-input',{attrs:{"type":"number"},model:{value:(line.quantity),callback:function ($$v) {_vm.$set(line, "quantity", $$v)},expression:"line.quantity"}})],1),_c('div',{staticClass:"flex-1 m-2"},[_c('t-select',{staticClass:"h-full",attrs:{"options":[
											{
												value: '',
												label: ''
											} ].concat( _vm.products.map(function (el) { return ({
												value: el.prices[0].id,
												label: el.label
											}); })
										)},model:{value:(line.priceId),callback:function ($$v) {_vm.$set(line, "priceId", $$v)},expression:"line.priceId"}})],1)]):(_vm.prices[key] && _vm.prices[key].pivot)?_c('div',[_vm._v(" + "+_vm._s(_vm.prices[key].pivot.quantity)+" x "),(
										_vm.prices[key].label ==
											_vm.$constants.TAGS.deliveringPrice
									)?_c('span',[_vm._v(" Frais de livraison ")]):_c('span',[_vm._v(" "+_vm._s(_vm.prices[key].products .map(function (el) { return el.label; }) .join(', '))+" ")]),_vm._v(" :: "+_vm._s(_vm.prices[key].promoPrice)+" "+_vm._s(_vm.currency)+" ")]):_vm._e()])})],2)}),(_vm.isEditing)?_c('div',{staticClass:"px-2"},[_c('t-button',{staticClass:"ml-auto bg-warning text-xs",on:{"click":function($event){return _vm.addPrice(_vm.command.clientId)}}},[_c('i',{staticClass:"icofont-plus-circle"}),_vm._v(" ADD LINE ")])],1):_vm._e(),_vm._m(0),(!_vm.isEditing)?_c('h4',{staticClass:"price text-success mt-8"},[_vm._v(" TOTAL: "),_c('span',[_vm._v(_vm._s(Number(_vm.total).toFixed(2))+" "+_vm._s(_vm.currency))])]):_c('div',[_c('t-button',{staticClass:"w-full py-3 bg-success",on:{"click":_vm.updateCommand}},[_vm._v("EDIT")])],1)],2)])])]),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"}),_c('div',{staticClass:"col-md-9"})])}]

export { render, staticRenderFns }