<template>
	<a @click="$emit('click', data.id)" class="text-decoration-none text-dark">
		<div class="p-2 rounded shadow-sm bg-white">
			<div class="align-items-center mb-1" style="position: relative;">
				<div
					:style="
						data.prices &&
						data.prices.length > 0 &&
						data.prices[0].image != '---'
							? `background-image: url(${$constants.STORAGE_PATH}${data.prices[0].image}); background-size: cover`
							: `background-image: url(${defaultImage}); background-color: #b5ae94; background-size: contain`
					"
					class="mx-auto shadow-sm rounded w-full h-32 bg-no-repeat bg-center"
					alt="Responsive image"
				/>
				<a
					class="absolute top-0 right-0 my-1 mx-1 font-weight-bold text-white bg-magenta text-decoration-none px-4 rounded-full m-1 uppercase"
				>
					{{ data.state.label }}
				</a>
				<a
					v-if="isNotifiable"
					class="absolute top-0 left-0 my-2 mx-1 font-weight-bold text-white text-decoration-none"
					@click.stop="$emit('notify', data.id)"
				>
					<i
						v-if="!notifications"
						class="icofont-notification p-2 bg-warning shadow rounded-circle"
					/>
					<div
						v-else
						class="p-2 flex items-center bg-danger shadow rounded-full h-6"
					>
						<i class="icofont-notification mr-2" />
						{{ notifications }}
					</div>
				</a>
				<a
					v-if="isEditable"
					class="absolute bottom-0 right-0 mx-1 my-2 font-weight-bold text-white text-decoration-none"
					@click.stop="$emit('edit', data.id)"
				>
					<i
						class="icofont-ui-edit p-2 bg-magenta shadow rounded-circle"
					/>
				</a>
			</div>
			<div class="d-flex">
				<div class=" font-weight-bold title-comd w-full">
					<div class="flex">
						<div class="flex-1 text-5">
							<span
								v-if="data.state.tag == 'pending-reservation'"
							>
								RESERVATION D'UNE TABLE
							</span>
							<span v-else>
								{{
									data.prices
										.flatMap(el => el.products)
										.flatMap(el => el.label)
										.join(' + ')
								}}
							</span>
							<small
								v-if="data.table && data.table.restaurant"
								class="block"
							>
								<strong>RESTAURANT</strong>
								{{ data.table.restaurant.name }}
							</small>
							<small
								v-if="data.table && data.table.restaurant"
								class="block"
							>
								<strong>TABLE N°</strong>
								{{ data.table.number }}
							</small>
						</div>
						<a
							v-if="
								!isValidatable &&
									data.prices &&
									data.prices.length > 0 &&
									data.prices[0].products &&
									data.prices[0].products.length > 0
							"
							@click.stop="
								$emit(
									'review',
									data.prices[0].products[0].restaurantId
								)
							"
							class="mb-2 mr-2"
						>
							<p
								class="d-flex align-items-center text-muted ml-auto small m-0 "
							>
								<i
									:class="[
										'icofont-star star',
										data.table.restaurant.review_avg > 0
											? 'text-yellow-400'
											: 'text-grey-400'
									]"
								/>
								<i
									:class="[
										'icofont-star star',
										data.table.restaurant.review_avg > 1
											? 'text-yellow-400'
											: 'text-grey-400'
									]"
								/>
								<i
									:class="[
										'icofont-star star',
										data.table.restaurant.review_avg > 2
											? 'text-yellow-400'
											: 'text-grey-400'
									]"
								/>
								<i
									:class="[
										'icofont-star star',
										data.table.restaurant.review_avg > 3
											? 'text-yellow-400'
											: 'text-grey-400'
									]"
								/>
								<i
									:class="[
										'icofont-star star',
										data.table.restaurant.review_avg > 4
											? 'text-yellow-400'
											: 'text-grey-400'
									]"
								/>
							</p>
							<div class="text-grey-400">
								<small>*add a review</small>
							</div>
						</a>
					</div>
					<div class="text-muted ml-auto small m-0">
						{{
							textTruncate(
								data.prices
									.flatMap(el => el.products)
									.flatMap(el => el.description)
									.join(' + ')
							)
						}}
					</div>
					<div v-if="isValidatable" class="w-full mt-2">
						<t-button
							v-if="data.state.tag == 'pending'"
							@click.stop="markValidated"
							class="small w-full bg-success"
						>
							VALIDATED
						</t-button>
						<t-button
							v-if="data.state.tag == 'validated'"
							@click.stop="markReady"
							class="small w-full bg-success"
						>
							READY
						</t-button>
						<t-button
							v-if="data.state.tag == 'ready'"
							@click.stop="markDelivered"
							class="small w-full bg-success"
						>
							DELIVERED
						</t-button>
						<t-button
							v-if="data.state.tag == 'delivered'"
							@click.stop="markPayed"
							class="small w-full bg-success"
						>
							PAYED
						</t-button>
					</div>
					<div
						v-if="
							data.state.tag == 'pending' ||
								data.state.tag == 'pending-reservation' ||
								data.state.tag == 'validated'
						"
						class="w-full mt-2"
					>
						<t-button
							@click.stop="markCanceled"
							class="small w-full bg-danger"
						>
							CANCELED
						</t-button>
					</div>
				</div>
			</div>
		</div>
	</a>
</template>

<script>
import { textTruncate } from '@/tools/helpers';
import {
	ApiCancelCommand,
	ApiPostCommandDelivered,
	ApiPostCommandReady,
	ApiPostCommandValidated,
	ApiPostCommandPayed
} from '@/tools/apiService';
export default {
	props: [
		'data',
		'notifications',
		'isEditable',
		'isNotifiable',
		'isValidatable'
	],
	data: () => ({
		defaultImage: require('@/assets/images/order-placeholder.png'),
		isRestaurantRateOpen: false
	}),
	methods: {
		textTruncate,
		markCanceled: function() {
			ApiCancelCommand(this.data.id).then(response => {
				this.data.state = response.data.state;
			});
		},
		markReady: function() {
			ApiPostCommandReady(this.data.id).then(response => {
				this.data.state = response.data.state;
			});
		},
		markDelivered: function() {
			ApiPostCommandDelivered(this.data.id).then(response => {
				this.data.state = response.data.state;
			});
		},
		markValidated: function() {
			ApiPostCommandValidated(this.data.id).then(response => {
				this.data.state = response.data.state;
			});
		},
		markPayed: function() {
			ApiPostCommandPayed(this.data.id).then(response => {
				this.data.state = response.data.state;
			});
		}
	},
	computed: {}
};
</script>

<style></style>
