<template>
	<div>
		<div class="card px-2 py-4">
			<div class="container-fliud">
				<div class="wrapper row">
					<div class="preview col-md-6">
						<div class="preview-pic tab-content">
							<div v-if="activatedImage" class="tab-pane active">
								<img class="rounded" :src="activatedImage" />
							</div>
						</div>
						<ul
							v-if="images.length > 1"
							class="preview-thumbnail nav nav-tabs"
						>
							<li
								v-for="(image, key) in images"
								:key="`command-price-${key}`"
							>
								<a
									@click="selectImage(image)"
									:class="[
										'thumbnail rounded relative block',
										activatedImage == image
											? ''
											: 'disabled'
									]"
								>
									<div
										class="rounded bg-cover w-16 h-16"
										:style="
											`background-image: url(${image})`
										"
									/>
								</a>
							</li>
						</ul>
						<hr />
					</div>
					<div class="details col-md-6">
						<h6 class="-my-1">
							{{ formatDate(command.created_at, true) }}
						</h6>
						<br />
						<!-- <div>
							<span class="review-no">
								<h6>Mode Payement:</h6>
								<div class="bg-grey-100 p-2 my-2 rounded">
									{{ command.payement_mode.label }}
								</div>
							</span>
						</div> -->
						<div v-if="command.table && command.table.restaurant">
							<span class="review-no">
								<h6>Restaurant:</h6>
								<div class="bg-grey-100 p-2 my-2 rounded">
									{{ command.table.restaurant.name }}
								</div>
							</span>
						</div>
						<div v-if="command.client">
							<span class="review-no">
								<h6>Client:</h6>
								<div class="bg-grey-100 p-2 my-2 rounded">
									{{ command.client.user.name }}
								</div>
							</span>
						</div>
						<div v-if="command.table && command.table">
							<span class="review-no">
								<h6>Table:</h6>
								<div class="bg-grey-100 p-2 my-2 rounded">
									<div v-if="!isEditing">
										#{{ command.table.number }}
									</div>
									<div v-else>
										<t-select
											v-model="form.tableId"
											:options="[
												{
													value: '',
													label: ''
												},
												...tables.map(el => ({
													value: el.id,
													label: `#${el.number}`
												}))
											]"
										/>
									</div>
								</div>
							</span>
						</div>
						<div v-if="command.planifiedFor">
							<span class="review-no">
								<h6>Planifier pour:</h6>
								<div class="bg-grey-100 p-2 my-2 rounded">
									<div v-if="!isEditing">
										{{ command.planifiedFor }}
									</div>
									<div v-else>
										<t-datepicker
											v-model="form.planifiedFor"
										/>
									</div>
								</div>
							</span>
						</div>
						<div v-if="command.comment">
							<span class="review-no">
								<h6>Commentaire:</h6>
								<div class="bg-grey-100 p-2 my-2 rounded">
									<div v-if="!isEditing">
										{{ command.comment }}
									</div>
									<div v-else>
										<t-textarea
											v-model="form.comment"
											type="text"
										/>
									</div>
								</div>
							</span>
						</div>
						<div
							v-if="command.address.description != 'N/A'"
							class="vote"
						>
							<h6>Adresse:</h6>
							<div class="bg-grey-100 p-2 my-2 rounded">
								<div v-if="!isEditing">
									{{ command.address.description }}
								</div>
								<div v-else>
									<t-input
										v-model="command.address.description"
										type="text"
									/>
								</div>
							</div>
						</div>
						<h6 class="mb-2">Les lignes de commande:</h6>
						<div
							v-for="(clientId, key) in clients"
							:key="`client-${key}`"
							class="bg-grey-100 p-2 my-2 rounded"
						>
							<div
								v-if="
									getUserByClientId(clientId).id ==
										command.clientId
								"
								class="p-2 -m-2 mb-2 bg-grey-300"
							>
								<span class="uppercase">{{
									getUserByClientId(clientId).name
								}}</span>
								-
								{{ getClientById(clientId).phone }}
							</div>
							<div
								v-for="(line, key) in form.lines.filter(
									el => el.clientId == clientId
								)"
								class="my-2"
								:key="`line-${key}`"
							>
								<div v-if="isEditing" class="flex items-center">
									<div class="m-2">
										<button
											@click="removePrice(key)"
											class="m-auto bg-red-600 text-white px-2 py-1 text-xs w-full rounded"
										>
											<i class="icofont-ui-remove"></i>
										</button>
									</div>
									<div class="w-16 m-2">
										<t-input
											type="number"
											v-model="line.quantity"
										/>
									</div>
									<div class="flex-1 m-2">
										<t-select
											v-model="line.priceId"
											class="h-full"
											:options="[
												{
													value: '',
													label: ''
												},
												...products.map(el => ({
													value: el.prices[0].id,
													label: el.label
												}))
											]"
										/>
									</div>
								</div>
								<div
									v-else-if="prices[key] && prices[key].pivot"
								>
									+ {{ prices[key].pivot.quantity }} x
									<span
										v-if="
											prices[key].label ==
												$constants.TAGS.deliveringPrice
										"
									>
										Frais de livraison
									</span>
									<span v-else>
										{{
											prices[key].products
												.map(el => el.label)
												.join(', ')
										}}
									</span>
									:: {{ prices[key].promoPrice }}
									{{ currency }}
								</div>
							</div>
						</div>
						<div v-if="isEditing" class="px-2">
							<t-button
								@click="addPrice(command.clientId)"
								class="ml-auto bg-warning text-xs"
							>
								<i class="icofont-plus-circle"></i> ADD LINE
							</t-button>
						</div>
						<div>
							<hr />
						</div>
						<h4 v-if="!isEditing" class="price text-success mt-8">
							TOTAL:
							<span
								>{{ Number(total).toFixed(2) }}
								{{ currency }}</span
							>
						</h4>
						<div v-else>
							<t-button
								@click="updateCommand"
								class="w-full py-3 bg-success"
								>EDIT</t-button
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-3"></div>
			<div class="col-md-9"></div>
		</div>
	</div>
</template>

<script>
import { formatDate, cloneObject } from '@/tools/helpers';
import { mapGetters } from 'vuex';
import {
	ApiGetProducts,
	ApiGetRestaurantTables,
	ApiUpdateCommand
} from '@/tools/apiService';

export default {
	props: ['command', 'total', 'prices', 'withCancel', 'isEditing'],
	data: () => ({
		activatedImage: null,
		tables: [],
		form: {
			lines: [],
			tableId: null
		},
		products: []
	}),
	mounted: function() {
		this.fillForm();
		this.activatedImage = this.images[0];
		if (
			this.isEditing &&
			this.command &&
			this.command.table &&
			this.command.table.restaurant
		)
			this.getProducts(this.command.table.restaurant.id);
	},
	watch: {
		isEditing: function() {
			this.getProducts();
		},
		'command.id': function() {
			this.fillForm();
		}
	},
	methods: {
		formatDate: formatDate,
		fillForm: function() {
			this.form = {
				id: this.command.id,
				comment: this.command.comment,
				planifiedFor: this.command.planifiedFor,
				tableId: this.command.table.id,
				lines: this.prices.map(el => ({
					id: el.pivot.id,
					priceId: el.id,
					quantity: el.pivot.quantity,
					clientId: el.pivot.client.id,
					promoPrice: el.promoPrice
				}))
			};
			if (this.prices.length == 0) this.addPrice(this.command.clientId);
		},
		selectImage: function(image) {
			this.activatedImage = image;
		},
		cancel: function() {
			this.$emit('close');
		},
		getClientById: function(id) {
			let client = this.prices
				.filter(el => el.pivot && el.pivot.client)
				.map(el => el.pivot.client)
				.find(el => el.id == id);
			return client || {};
		},
		getUserByClientId: function(id) {
			let client = this.prices
				.filter(el => el.pivot && el.pivot.client)
				.map(el => el.pivot.client)
				.find(el => el.id == id);
			return client && client.user ? client.user : {};
		},
		getTables: function(restaurantId) {
			ApiGetRestaurantTables(restaurantId).then(response => {
				this.tables = response.data.tables;
			});
		},
		getProducts: function(restaurantId) {
			ApiGetProducts({
				criterias: { restaurantId },
				withRelations: ['category', 'prices']
			}).then(reponse => {
				this.products = reponse.data.products;
				this.getTables(restaurantId);
			});
		},
		addPrice: function(clientId) {
			this.form.lines = [
				...this.form.lines,
				{
					id: null,
					quantity: 0,
					clientId: clientId
				}
			];
		},
		removePrice: function(index) {
			this.form.lines.splice(index, 1);
		},
		updateCommand: function() {
			let form = cloneObject(this.form);
			form.lines = form.lines.filter(el => el.priceId);
			ApiUpdateCommand(this.command.id, this.form).then(() => {
				this.$emit('edited');
			});
		}
	},
	computed: {
		...mapGetters({
			currency: 'lang/getCurrency'
		}),
		internalTotal: function() {
			return this.form.lines.reduce(
				(result, price) => result + price.promoPrice,
				0
			);
		},
		images: function() {
			let images = this.prices
				.filter(el => el.label != this.$constants.TAGS.deliveringPrice)
				.map(el => `${this.$constants.STORAGE_PATH}${el.image}`);

			return [...new Set(images)];
		},
		clients: function() {
			return [...new Set(this.form.lines.map(el => el.clientId))];
		}
	}
};
</script>
<style scoped>
.old-price {
	text-decoration: line-through;
	color: #ddd;
}
.thumbnail {
	position: relative;
	display: block;
}
.thumbnail.disabled::after {
	content: ' ';
	width: 100%;
	height: 100%;
	padding: 2rem;
	background-color: black;
	border-radius: 0.5rem;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
}
</style>
