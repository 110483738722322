<template>
	<div>
		<t-modal v-model="isNotificationOpen">
			<messanger
				v-if="
					selectedCommand &&
						selectedCommand.delivery_men &&
						selectedCommand.delivery_men.length > 0
				"
				:senderId="user.id"
				:receiverId="
					selectedCommand.delivery_men[0].id != user.id
						? selectedCommand.delivery_men[0].id
						: selectedCommand.client.userId
				"
				:notice="
					role.name != 'livreur' && selectedCommand
						? `Nous serions ravis de vous entendre`
						: null
				"
				:commandId="selectedCommand.id"
			></messanger>
		</t-modal>
		<t-modal v-model="isDetailOpen">
			<command-detail
				v-if="selectedCommand"
				:is-editing="isEditing"
				:command="selectedCommand"
				:total="getTotal(selectedCommand)"
				:prices="getPrices(selectedCommand)"
				@edited="refresh"
			/>
		</t-modal>
		<div v-if="commands.length == 0" class="order-body p-3">
			<div class="p-4 text-grey-500 bg-white text-center shadow rounded">
				aucune commande
			</div>
		</div>
		<div class="order-body p-3">
			<div class="pb-3" v-for="(command, key) in commands" :key="key">
				<command
					@click="openDetail"
					@review="openRestaurantReview"
					@edit="openEditDetail"
					@notify="openNotificationDetail"
					:notifications="
						$root.notifications.filter(
							el =>
								el.subject ==
								`boite message pour la commande ${command.id}`
						).length
					"
					:data="command"
					:isEditable="
						['pending', 'pending-reservation'].includes(
							command.state.tag
						)
					"
					:isNotifiable="
						['validated', 'ready', 'delivered'].includes(
							command.state.tag
						) &&
							command.delivery_men &&
							command.delivery_men.length > 0
					"
					:isValidatable="role.name == 'livreur'"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiProfileCommands } from '@/tools/apiService';
import { mapGetters, mapActions } from 'vuex';
import Command from './component/Command';
import CommandDetail from './component/CommandDetail';
import Messanger from '@/components/Messanger';

export default {
	data: () => ({
		polling: null,
		isActive: true,
		isHistory: true,
		isNotificationOpen: false,
		isDetailOpen: false,
		isEditing: false,
		commands: [],
		selectedCommandId: null
	}),
	mounted: function() {
		this.isActive = this.$route.query.isActive;
		this.isHistory = this.$route.query.isHistory;
		if (this.role.name == 'livreur') this.isActive = true;
		this.getCommands();
	},
	watch: {
		$route: function() {
			this.isActive = this.$route.query.isActive;
			this.isHistory = this.$route.query.isHistory;
			if (this.role.name == 'livreur') this.isActive = true;
			this.getCommands();
		}
	},
	components: {
		Command,
		CommandDetail,
		Messanger
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			role: 'auth/role'
		}),
		selectedCommand: function() {
			return this.commands.find(el => el.id == this.selectedCommandId);
		}
	},
	beforeDestroy() {
		clearInterval(this.polling);
	},
	methods: {
		...mapActions({
			openRestaurantReview: 'notification/openRestaurantReview'
		}),
		refresh: function() {
			this.isEditing = false;
			this.isDetailOpen = false;
			this.selectedCommandId = null;
			this.isNotificationOpen = false;
			this.getCommands();
		},
		openDetail: function(id) {
			this.isDetailOpen = true;
			this.selectedCommandId = id;
			this.isNotificationOpen = false;
			this.isEditing = false;
		},
		openNotificationDetail: function(id) {
			this.isNotificationOpen = true;
			this.selectedCommandId = id;
			this.isDetailOpen = false;
			this.isEditing = false;
		},
		openEditDetail: function(id) {
			this.isDetailOpen = true;
			this.selectedCommandId = id;
			this.isEditing = true;
			this.isNotificationOpen = false;
		},
		getCommands: function() {
			ApiProfileCommands(this.isActive, this.isHistory).then(response => {
				this.commands = response.data.commands;
			});
		},
		getTotal: function(command) {
			return command.customTotal || command.total;
		},
		getPrices: function(command) {
			let prices = command.customPrices || command.prices;
			return prices.filter(
				el => el.label != this.$constants.TAGS.deliveringPrice
			);
		}
	}
};
</script>

<style>
.title-comd {
	font-size: 25px;
	color: #726837;
}
</style>
