var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"text-decoration-none text-dark",on:{"click":function($event){return _vm.$emit('click', _vm.data.id)}}},[_c('div',{staticClass:"p-2 rounded shadow-sm bg-white"},[_c('div',{staticClass:"align-items-center mb-1",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"mx-auto shadow-sm rounded w-full h-32 bg-no-repeat bg-center",style:(_vm.data.prices &&
					_vm.data.prices.length > 0 &&
					_vm.data.prices[0].image != '---'
						? ("background-image: url(" + (_vm.$constants.STORAGE_PATH) + (_vm.data.prices[0].image) + "); background-size: cover")
						: ("background-image: url(" + _vm.defaultImage + "); background-color: #b5ae94; background-size: contain")),attrs:{"alt":"Responsive image"}}),_c('a',{staticClass:"absolute top-0 right-0 my-1 mx-1 font-weight-bold text-white bg-magenta text-decoration-none px-4 rounded-full m-1 uppercase"},[_vm._v(" "+_vm._s(_vm.data.state.label)+" ")]),(_vm.isNotifiable)?_c('a',{staticClass:"absolute top-0 left-0 my-2 mx-1 font-weight-bold text-white text-decoration-none",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('notify', _vm.data.id)}}},[(!_vm.notifications)?_c('i',{staticClass:"icofont-notification p-2 bg-warning shadow rounded-circle"}):_c('div',{staticClass:"p-2 flex items-center bg-danger shadow rounded-full h-6"},[_c('i',{staticClass:"icofont-notification mr-2"}),_vm._v(" "+_vm._s(_vm.notifications)+" ")])]):_vm._e(),(_vm.isEditable)?_c('a',{staticClass:"absolute bottom-0 right-0 mx-1 my-2 font-weight-bold text-white text-decoration-none",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit', _vm.data.id)}}},[_c('i',{staticClass:"icofont-ui-edit p-2 bg-magenta shadow rounded-circle"})]):_vm._e()]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:" font-weight-bold title-comd w-full"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1 text-5"},[(_vm.data.state.tag == 'pending-reservation')?_c('span',[_vm._v(" RESERVATION D'UNE TABLE ")]):_c('span',[_vm._v(" "+_vm._s(_vm.data.prices .flatMap(function (el) { return el.products; }) .flatMap(function (el) { return el.label; }) .join(' + '))+" ")]),(_vm.data.table && _vm.data.table.restaurant)?_c('small',{staticClass:"block"},[_c('strong',[_vm._v("RESTAURANT")]),_vm._v(" "+_vm._s(_vm.data.table.restaurant.name)+" ")]):_vm._e(),(_vm.data.table && _vm.data.table.restaurant)?_c('small',{staticClass:"block"},[_c('strong',[_vm._v("TABLE N°")]),_vm._v(" "+_vm._s(_vm.data.table.number)+" ")]):_vm._e()]),(
							!_vm.isValidatable &&
								_vm.data.prices &&
								_vm.data.prices.length > 0 &&
								_vm.data.prices[0].products &&
								_vm.data.prices[0].products.length > 0
						)?_c('a',{staticClass:"mb-2 mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.$emit(
								'review',
								_vm.data.prices[0].products[0].restaurantId
							)}}},[_c('p',{staticClass:"d-flex align-items-center text-muted ml-auto small m-0 "},[_c('i',{class:[
									'icofont-star star',
									_vm.data.table.restaurant.review_avg > 0
										? 'text-yellow-400'
										: 'text-grey-400'
								]}),_c('i',{class:[
									'icofont-star star',
									_vm.data.table.restaurant.review_avg > 1
										? 'text-yellow-400'
										: 'text-grey-400'
								]}),_c('i',{class:[
									'icofont-star star',
									_vm.data.table.restaurant.review_avg > 2
										? 'text-yellow-400'
										: 'text-grey-400'
								]}),_c('i',{class:[
									'icofont-star star',
									_vm.data.table.restaurant.review_avg > 3
										? 'text-yellow-400'
										: 'text-grey-400'
								]}),_c('i',{class:[
									'icofont-star star',
									_vm.data.table.restaurant.review_avg > 4
										? 'text-yellow-400'
										: 'text-grey-400'
								]})]),_vm._m(0)]):_vm._e()]),_c('div',{staticClass:"text-muted ml-auto small m-0"},[_vm._v(" "+_vm._s(_vm.textTruncate( _vm.data.prices .flatMap(function (el) { return el.products; }) .flatMap(function (el) { return el.description; }) .join(' + ') ))+" ")]),(_vm.isValidatable)?_c('div',{staticClass:"w-full mt-2"},[(_vm.data.state.tag == 'pending')?_c('t-button',{staticClass:"small w-full bg-success",on:{"click":function($event){$event.stopPropagation();return _vm.markValidated.apply(null, arguments)}}},[_vm._v(" VALIDATED ")]):_vm._e(),(_vm.data.state.tag == 'validated')?_c('t-button',{staticClass:"small w-full bg-success",on:{"click":function($event){$event.stopPropagation();return _vm.markReady.apply(null, arguments)}}},[_vm._v(" READY ")]):_vm._e(),(_vm.data.state.tag == 'ready')?_c('t-button',{staticClass:"small w-full bg-success",on:{"click":function($event){$event.stopPropagation();return _vm.markDelivered.apply(null, arguments)}}},[_vm._v(" DELIVERED ")]):_vm._e(),(_vm.data.state.tag == 'delivered')?_c('t-button',{staticClass:"small w-full bg-success",on:{"click":function($event){$event.stopPropagation();return _vm.markPayed.apply(null, arguments)}}},[_vm._v(" PAYED ")]):_vm._e()],1):_vm._e(),(
						_vm.data.state.tag == 'pending' ||
							_vm.data.state.tag == 'pending-reservation' ||
							_vm.data.state.tag == 'validated'
					)?_c('div',{staticClass:"w-full mt-2"},[_c('t-button',{staticClass:"small w-full bg-danger",on:{"click":function($event){$event.stopPropagation();return _vm.markCanceled.apply(null, arguments)}}},[_vm._v(" CANCELED ")])],1):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-grey-400"},[_c('small',[_vm._v("*add a review")])])}]

export { render, staticRenderFns }