var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{model:{value:(_vm.isNotificationOpen),callback:function ($$v) {_vm.isNotificationOpen=$$v},expression:"isNotificationOpen"}},[(
				_vm.selectedCommand &&
					_vm.selectedCommand.delivery_men &&
					_vm.selectedCommand.delivery_men.length > 0
			)?_c('messanger',{attrs:{"senderId":_vm.user.id,"receiverId":_vm.selectedCommand.delivery_men[0].id != _vm.user.id
					? _vm.selectedCommand.delivery_men[0].id
					: _vm.selectedCommand.client.userId,"notice":_vm.role.name != 'livreur' && _vm.selectedCommand
					? "Nous serions ravis de vous entendre"
					: null,"commandId":_vm.selectedCommand.id}}):_vm._e()],1),_c('t-modal',{model:{value:(_vm.isDetailOpen),callback:function ($$v) {_vm.isDetailOpen=$$v},expression:"isDetailOpen"}},[(_vm.selectedCommand)?_c('command-detail',{attrs:{"is-editing":_vm.isEditing,"command":_vm.selectedCommand,"total":_vm.getTotal(_vm.selectedCommand),"prices":_vm.getPrices(_vm.selectedCommand)},on:{"edited":_vm.refresh}}):_vm._e()],1),(_vm.commands.length == 0)?_c('div',{staticClass:"order-body p-3"},[_c('div',{staticClass:"p-4 text-grey-500 bg-white text-center shadow rounded"},[_vm._v(" aucune commande ")])]):_vm._e(),_c('div',{staticClass:"order-body p-3"},_vm._l((_vm.commands),function(command,key){return _c('div',{key:key,staticClass:"pb-3"},[_c('command',{attrs:{"notifications":_vm.$root.notifications.filter(
						function (el) { return el.subject ==
							("boite message pour la commande " + (command.id)); }
					).length,"data":command,"isEditable":['pending', 'pending-reservation'].includes(
						command.state.tag
					),"isNotifiable":['validated', 'ready', 'delivered'].includes(
						command.state.tag
					) &&
						command.delivery_men &&
						command.delivery_men.length > 0,"isValidatable":_vm.role.name == 'livreur'},on:{"click":_vm.openDetail,"review":_vm.openRestaurantReview,"edit":_vm.openEditDetail,"notify":_vm.openNotificationDetail}})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }